import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";

import { IconEdit } from "@lightspeed/flame/Icon/Edit";

import { Td, Tr } from "@lightspeed/cirrus-table";
import { Input } from "@lightspeed/flame/Input";
import { Button } from "@lightspeed/flame/Button";
import { setAlert, setPopup, uiSelector } from "../../../redux/slices/uiSlice";
import {
  updateFloor,
  updateFloorState,
} from "../../../redux/slices/companiesSlice";

// icons
import trash from "../../../assets/trash.png";
import whitetrash from "../../../assets/whitetrash.png";
import editIcon from "../../../assets/edit.png";
import whiteedit from "../../../assets/whiteedit.png";

const TdStyled = styled.td`
  padding: 0 8px !important;
  height: 0 !important;
`;

const Row = ({ item, editable, updateAction, rowKeys }) => {
  const [edit, editSet] = useState(false);
  const { register, handleSubmit, setValue } = useForm();
  const dispatch = useDispatch();
  const { theme } = useSelector(uiSelector);

  const onUpdate = (e) => {
    let areSame = true;
    editable.forEach((editableKey) => {
      const old = item[editableKey];
      const edited = e[editableKey];
      if (old !== edited) areSame = false;
    });

    if (areSame) {
      return editSet(false);
    }

    const newItem = { ...item, ...e };
    editSet(false);
    dispatch(updateAction(newItem)).then((res) => {
      dispatch(
        setAlert({
          type: "success",
          title: "Success",
          text: "Task completed successfuly",
        })
      );
      setTimeout(() => {
        dispatch(
          setAlert({
            type: "",
            title: "",
            text: "",
          })
        );
      }, 3000);
    });

    // show fail and reset
  };

  const onEdit = () => {
    editable.forEach((i) => setValue(i, item[i]));
    editSet(true);
  };

  const wrapOnUpdate = handleSubmit(onUpdate);

  return (
    <tr
      onDoubleClick={onEdit}
      className={`${theme === "flame" ? "" : "dark-table-row"}`}
    >
      {rowKeys.map((i) => {
        if (editable.includes(i) && edit) {
          return (
            <TdStyled key={Math.random()}>
              <Input
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    wrapOnUpdate();
                  }
                }}
                type={typeof item[i] === "number" ? "number" : "text"}
                {...register(i, {
                  valueAsNumber: typeof item[i] === "number" ? true : false,
                })}
              />
            </TdStyled>
          );
        }
        return <TdStyled key={Math.random()}>{item[i]}</TdStyled>;
      })}

      {edit ? (
        <TdStyled>
          <Button onClick={wrapOnUpdate}>Save</Button>
          <Button onClick={() => editSet(false)}>Cancel</Button>
        </TdStyled>
      ) : (
        <TdStyled>
          <Button onClick={onEdit}>
            <IconEdit baseColor={theme === "flame" ? "#333" : "#fff"} />
          </Button>
        </TdStyled>
      )}
    </tr>
  );
};

export default Row;
