import PivotTableUI from "react-pivottable/PivotTableUI";
import "react-pivottable/pivottable.css";
import { useState } from "react";
import { Card } from "@lightspeed/flame/Card";
import styled from "@emotion/styled";

const StyledCard = styled(Card)`
  flex-grow: 1;
  position: relative;
  overflow: auto;
`;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

const PivotTable = ({ data, rows, aggregatorName, vals, rendererName}) => {
  const [state, setState] = useState(data);

  return (
    <StyledCard>
      <Container>
        <PivotTableUI
          unusedOrientationCutoff={Infinity}
          data={data}
          onChange={(s) => setState(s)}
          rows={rows}
          aggregatorName={aggregatorName}
          vals={vals}
          rendererName={rendererName}
          {...state}
        />
      </Container>
    </StyledCard>
  );
};

export default PivotTable;
