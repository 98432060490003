import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { currentSelector } from "../redux/slices/companiesSlice";

const HomeStyle = styled.div`
  padding: 16px;
`;

const Home = () => {
  const { currentCompany } = useSelector(currentSelector);
  const { line_1, line_2, line_3, line_4, line_5, line_6 } = currentCompany;
  return (
    <HomeStyle>
      <p>{line_1}</p>
      <br />
      <p>{line_2}</p>
      <p>{line_3}</p>
      <br />
      <p>{line_4}</p>
      <p>{line_5}</p>
      <p>{line_6}</p>
    </HomeStyle>
  );
};

export default Home;
