import Table from "../ui/categoryTable/TableController";
import { useSelector, useDispatch } from "react-redux";
import { uiSelector } from "../../redux/slices/uiSlice";
import {
  companiesSelector,
  updateCategory,
  updateCategoryState,
} from "../../redux/slices/companiesSlice";
import Loading from "./tableState/Loading";
import NoSearch from "./tableState/NoSearch";
import NoItems from "./tableState/NoItems";

const Category = () => {
  const { categories, itemizers, tableLoading } =
    useSelector(companiesSelector);
  const dispatch = useDispatch();
  const { searchQuery } = useSelector(uiSelector);

  const filteredCategories = categories.filter(
    (i) =>
      i.category_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      String(i.category_id).includes(searchQuery) ||
      i.pms_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      String(i.pms_id).includes(searchQuery) ||
      i.category_type.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const headerData = [
    { id: 122, label: "Company", width: "16%", sortable: false },
    { id: 222, label: "Name", width: "16%", sortable: true },
    { id: 322, label: "Id", width: "16%", sortable: true },
    { id: 422, label: "PMS Name", width: "16%", sortable: true },
    { id: 522, label: "Category Type", width: "16%", sortable: true },
    { id: 622, label: "Action", width: "16%", sortable: false },
  ];

  const rowKeys = [
    "company_id",
    "category_name",
    "category_id",
    "pms_name",
    "category_type",
  ];

  const editable = ["pms_name", "category_type"];

  const editableOptions = {
    pms_name: itemizers.map((i) => ({ label: i.name, value: i.id })),
    category_type: [
      { value: "sale", label: "sale" },
      { value: "discount", label: "discount" },
      { value: "svc", label: "svc" },
    ],
  };

  const sortByData = [
    {},
    {
      label: "category_name",
      type: "string",
    },
    {
      label: "category_id",
      type: "number",
    },
    {
      label: "pas_name",
      type: "string",
    },
    {
      label: "category_type",
      type: "string",
    },
  ];

  const updateAction = (x) => {
    dispatch(updateCategoryState(x));
    return updateCategory(x);
  };

  if (tableLoading) return <Loading />;

  if (filteredCategories.length === 0 && searchQuery) return <NoSearch />;

  if (filteredCategories.length === 0) return <NoItems />;

  return (
    <Table
      headerData={headerData}
      rowKeys={rowKeys}
      rowKey="category_id"
      editableOptions={editableOptions}
      editable={editable}
      sortByData={sortByData}
      data={filteredCategories}
      updateAction={updateAction}
    />
  );
};

export default Category;
