import axios from "axios";

const baseUrl = process.env.REACT_APP_SERVER_URL;
const endPoint = "/tomi";
const url = baseUrl + endPoint;

export const api = {
  login: (data) => axios.post(baseUrl + "/login", data),
  // companies
  getCompanies: (data) => axios.post(baseUrl + "/company-list", data),
  syncCompany: (data) => axios.post(baseUrl + "/company-sync", data),
  // company table data
  getFloors: (data) => axios.post(baseUrl + "/company-floor-list", data),
  updateFloor: (data) => axios.post(baseUrl + "/company-floor-update", data),
  getItemizers: (data) => axios.post(baseUrl + "/company-itemizers-list", data),
  updateItemizer: (data) =>
    axios.post(baseUrl + "/company-itemizers-update", data),
  getCategories: (data) => axios.post(baseUrl + "/company-category-list", data),
  updateCategory: (data) =>
    axios.post(baseUrl + "/company-category-update", data),
  getPayments: (data) => axios.post(baseUrl + "/company-payment-list", data),
  updatePayment: (data) =>
    axios.post(baseUrl + "/company-payment-update", data),
  getLogs: (data) => axios.post(baseUrl + "/company-log", data),
  getReports: (data) => axios.post(baseUrl + "/company-report", data),
  // updateLog: (data) =>
  //   axios.post(baseUrl + "/company-payment-update", data),
};
