import { useSelector } from "react-redux";
import PivotTableUI from "react-pivottable/PivotTableUI";
import { Card } from "@lightspeed/flame/Card";
import Table from "../ui/reports/Table";
import {
  companiesSelector,
  getReports,
} from "../../redux/slices/companiesSlice";
import styled from "@emotion/styled";
import DatePicker from "../ui/reports/DatePicker";

const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
`;

const StyledCard = styled(Card)`
  position: relative;
  overflow: auto;
  height: 100%;
`;

const PivotTable = () => {
  const { reports } = useSelector(companiesSelector);

  const rows = ["SaleDateEST","SaleId","ItemName","TotalQtyCommission"];
  const aggregatorName = ["Sum"];
  const vals = ['TotalAmountCommission'];
  const cols2 = ["EmployeeName"];
  const rendererName = 'Table';

  return (
    <StyledCard>
      <PivotTableUI data={reports} rows={rows} cols={cols2} aggregatorName={aggregatorName} vals={vals} rendererName={rendererName} />
      <DatePicker updateAction={getReports} />
    </StyledCard>
  );
};

export default PivotTable;
